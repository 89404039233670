import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProgressIndicator from '../components/ProgressIndicator';
import ScoreTableNoButtons from '../components/ScoreTableNoButtons';
import { AuthContext } from '../firebase/authContext';
import { calculatePointsAndPlacements } from '../common/pointsCalculations';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import podium from '../img/printpodium.svg';
import { groupBy, orderBy } from 'lodash';
import firebase from '../firebase/firebase';
import Grid from '@material-ui/core/Grid';
import SVG from 'react-inlinesvg';
import * as constants from "../common/constants";

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  table: {
    minWidth: 700,
  },
  button: {
    color: '#FFF'
  },
  podiumContainer: {
    position: 'relative',
    margin: '0 auto',
    color: '#000',
    width: 600,
  },
  placementDiv: {
    position: 'absolute',
    left: '3px',
    width: 170,
    height: 20,
    textAlign: 'center',
    fontFamily: "Helvetica",
    textOverflow: 'hidden',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  secondPlaceDiv: {
    left: '32px',
  },
  firstPlaceDiv: {
    left: '212px',
  },
  thirdPlaceDiv: {
    left: '391px',
  },
  logoWrapper: {
    display: 'inline-block',
    textAlign: 'center',
    width: 150,
    height: 110,
    overflow: 'hidden'
  }
});

class Print extends React.Component {

  constructor(props) {
    super(props);

    this.imageIsLoaded = this.imageIsLoaded.bind(this);

    this.state = {
      title: '',
      items: null,
      firstPlaceItms: [],
      secondPlaceItms: [],
      thirdPlaceItms: [],
      logoUrl: null,
      imagesToLoad: null,
      podiumSecondColor: '#FFCE54',
      podiumFirstColor: '#FC6E51',
      podiumThirdColor: '#FFCE54'
    };
  }

  componentWillMount() {
    document.body.classList.remove("bgTexture");
  }

  imageIsLoaded() {

    var self = this;
    const { imagesToLoad } = this.state;
    var x = imagesToLoad;
    if (x > 0) {
      x--;
    }
    this.setState({
      imagesToLoad: x
    }, function () {
      const { imagesToLoad } = self.state;
      if (imagesToLoad === 0) {
        if(this.props.location.state.preview === false) {
        window.print();
        self.props.history.goBack();
      }
      }
    });
  }

  componentDidMount() {

    const customer = this.context.customer;
    document.title = "www.scoorly.se - Den digitala poängtavlan";

    var self = this;
    var itms = this.props.location.state.items;
    var title = this.props.location.state.title;

    //Räkna ut poäng och sortera spelarna.
    itms = JSON.parse(JSON.stringify(itms))
    itms[0].points.forEach((event, index) => {
      if (itms[0].points[index].points !== null)
        itms = calculatePointsAndPlacements(itms, index, itms[0].points[index].calculationCategory, false, customer);
    });

    //Sortera alltid spelarna efter placeringspoäng vid utskrift.
    itms = orderBy(itms, function (o) { return o.totalPlacementpoints });

    var x = groupBy(itms, 'placement');

    if(typeof customer !== "undefined" && typeof customer.calculationType !== "undefined" && customer.calculationType === constants.calculationTypes.totalPoints.value) {
      itms = orderBy(itms, function (o) { return o.totalPoints }, "desc");
    }

    var firstPlaceItms = [];
    var secondPlaceItms = [];
    var thirdPlaceItms = [];

    if (x.hasOwnProperty('1')) {
      firstPlaceItms = x['1'];
    }
    if (x.hasOwnProperty('2')) {
      secondPlaceItms = x['2'];
    }
    if (x.hasOwnProperty('3')) {
      thirdPlaceItms = x['3'];
    }

    var imagesToLoad = 0;
    if (this.props.location.state.includePodium === true) {
      imagesToLoad++;
    }
    if (customer.logoFileName) {
      imagesToLoad++;
    }

    var storageRef = firebase.storage().ref();
    //Hämtar logotypen.
    if (customer.logoFileName) {
      var logoRef = storageRef.child('images/' + customer.id + '/' + customer.logoFileName)
      logoRef.getDownloadURL().then(function (url) {
        self.setState({
          title: title,
          items: itms,
          firstPlaceItms: firstPlaceItms,
          secondPlaceItms: secondPlaceItms,
          thirdPlaceItms: thirdPlaceItms,
          logoUrl: url,
          imagesToLoad: imagesToLoad,
          podiumSecondColor: customer.podiumSecondColor,
          podiumFirstColor: customer.podiumFirstColor,
          podiumThirdColor: customer.podiumThirdColor
        }, function () {
          if (imagesToLoad === 0) {
            if(this.props.location.state.preview === false) {
            window.print();
            self.props.history.goBack();
            }
          }
        });
      });
    } else {
      self.setState({
        title: title,
        items: itms,
        firstPlaceItms: firstPlaceItms,
        secondPlaceItms: secondPlaceItms,
        thirdPlaceItms: thirdPlaceItms,
        logoUrl: null,
        imagesToLoad: imagesToLoad,
        podiumSecondColor: customer.podiumSecondColor,
        podiumFirstColor: customer.podiumFirstColor,
        podiumThirdColor: customer.podiumThirdColor
      }, function () {
        if (imagesToLoad === 0) {
          if(this.props.location.state.preview === false) {
          window.print();
          self.props.history.goBack();
          }
        }
      });
    }
  }

  static contextType = AuthContext;
  render() {

    const { classes } = this.props;
    const { items, title, logoUrl, firstPlaceItms, secondPlaceItms, thirdPlaceItms,
      podiumSecondColor, podiumFirstColor, podiumThirdColor } = this.state;
    var secondPlaceBottomMargin = 137;
    var firstPlaceBottomMargin = 234;
    var thirdPlaceBottomMargin = 137;
    if (items !== null) {

      return (
        <React.Fragment>
          <div className={classes.root}>


            <Grid container spacing={24}>
              <Grid item xs={6} sm={6}>
                <Typography style={{ fontSize: 20, color: '#000' }} >
                  {title}
                  <br />
                  {moment().format('YYYY-MM-DD')}
                </Typography>
              </Grid>

              <Grid style={{ textAlign: 'right' }} item xs={6} sm={6}>

                {logoUrl && <div className={classes.logoWrapper}>
                  <img src={logoUrl} alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} onLoad={this.imageIsLoaded} />
                </div>}

              </Grid>
            </Grid>

            {this.props.location.state.includePodium && <div className={classes.podiumContainer}>

              {/* <img src={podium} alt="Logo" style={{width: '100%'}} onLoad={this.imageIsLoaded}/> */}

              <SVG
                cacheRequests={true}
                description="The React logo"
                onError={error => console.log(error.message)}
                onLoad={this.imageIsLoaded}
                preProcessor={code => {
                  code = code.replace('podiumSecondColor', podiumSecondColor);
                  code = code.replace('podiumFirstColor', podiumFirstColor);
                  code = code.replace('podiumThirdColor', podiumThirdColor);
                  return code;
                }}
                src={podium}
                uniqueHash="a1f8d1"
                uniquifyIDs={true}
              />


              {secondPlaceItms.map((itm, index) => {
                secondPlaceBottomMargin += 25;
                return (<div style={{ bottom: secondPlaceBottomMargin }} key={itm.id} className={classes.placementDiv + ' ' + classes.secondPlaceDiv}><span>{itm.name}</span></div>)
              })}

              {firstPlaceItms.map((itm, index) => {
                firstPlaceBottomMargin += 25;
                return (<div style={{ bottom: firstPlaceBottomMargin }} key={itm.id} className={classes.placementDiv + ' ' + classes.firstPlaceDiv}><span>{itm.name}</span></div>)
              })}

              {thirdPlaceItms.map((itm, index) => {
                thirdPlaceBottomMargin += 25;
                return (<div style={{ bottom: thirdPlaceBottomMargin }} key={itm.id} className={classes.placementDiv + ' ' + classes.thirdPlaceDiv}><span>{itm.name}</span></div>)
              })}
            </div>}

            <ScoreTableNoButtons
              items={items}
              points={items[0].points}
              infoText={''}
              onPrintRoute={true}
            />


          </div>
        </React.Fragment>


      )
    }
    return (<ProgressIndicator />)
  }
}

export default withStyles(styles)(Print);