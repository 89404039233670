import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
const styles = theme => ({

  buttonColors: {
    border: '0px solid #509CF5',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    },
  },
  buttonAddPlayer: {
    fontSize: '0.9em',
    color: '#FFF',
    height: 55,
  },
  textField: {
    width: '100%',
    minWidth: '100%',
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0
  }
});

class OtherInfoInput extends React.Component {

  constructor() {
    super()

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    this.state = {
      otherInfo: '',
      showTextFieldError: false
    };
  }

  handleTextChange(e) {
    this.setState({
      otherInfo: e.target.value,
      showTextFieldError: false
    });

    this.props.onOtherInfoChange(e.target.value);
  };

  handleBlur() {
    this.props.onBlur();
  };

  render() {

    const { classes, content } = this.props;
    const { showTextFieldError } = this.state;

    return (
      <TextField
        multiline
        style={{ marginTop: 20 }}
        rows={15}
        error={showTextFieldError}
        id="outlined-name"
        label="Övrig information (valfritt):"
        className={classes.textField}
        value={content}
        onChange={this.handleTextChange}
        margin="normal"
        variant="outlined"
        onBlur={this.handleBlur}
      />
    );
  }
}
export default withStyles(styles)(OtherInfoInput);
