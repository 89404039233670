import * as constants from "../common/constants";
import { findIndex, orderBy } from 'lodash';

export function getCategory(event) {
  if (event.calculationCategory === constants.calculationCategories.highest.value) {
    return "Högsta";
  } else if (event.calculationCategory === constants.calculationCategories.lowest.value) {
    return "Lägsta";
  }
  else if (event.calculationCategory === constants.calculationCategories.closest.value) {
    return "Närmast";
  }
}

export function getEventFormat(event) {
  if (event.calculationFormat === constants.calculationFormats.time.value) {
    return "Tid";
  }
  else {
    return "Poäng";
  }
}

export function renderPoints(points) { 

  if (typeof points.calculationFormat !== 'undefined' &&
  points.calculationFormat === constants.calculationFormats.time.value) {
    let seconds = getSeconds(points.points);
    let minutes = getMinutes(points.points);
    let hundreths = getHundreths(points.points);
    return `${minutes}:${seconds}.${hundreths}`;
}
 const unit = typeof points.unit === "undefined" ? 'p' : points.unit;
 return points.points + `${unit}`;
}

//Sorterar spelare beroende på om sortering är på eller avslagen.
export function sortPlayers(sortingEnabled, players, customer) {

  if (sortingEnabled === true && players.length > 0) {
    //Sortera spelarna efter placeringspoäng på skiljegrenen och sedan på totala placeringspoängen (totalPlacementpoints) lägsta högst upp = leder
    const dividingEventIndex = findIndex(players[0].points, function (o) { return o.isDividingEvent === true });
    if(typeof customer !== "undefined" && typeof customer.calculationType !== "undefined" && customer.calculationType === constants.calculationTypes.totalPoints.value) {
      if (dividingEventIndex > -1) {
        players = orderBy(players, function (o) { return o.points[dividingEventIndex].points }, "desc");
      }
      players = orderBy(players, function (o) { return o.totalPoints }, "desc");
    } else {
      if (dividingEventIndex > -1) {
        players = orderBy(players, function (o) { return o.points[dividingEventIndex].placementpoints });
      }
      players = orderBy(players, function (o) { return o.totalPlacementpoints });
    }
  } else {
    //Om sortering är avstängd. Sortera spelarna efter created timestamp.
    players = orderBy(players, function (o) { return o.created.seconds });
  }
  return players;
}

export function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function placementIsShown(itms, index) {

  var itmBefore = itms[index - 1];
  var itm = itms[index];

  if (itm.placement === null) {
    return false;
  }

  if (index === 0) {
    return true;
  }

  if (typeof itmBefore !== 'undefined') {
    if (itm.placement === itmBefore.placement) {
      return false;
    }
    return true;
  }
}

export function formatMinutes(inputValue, points, characterIndexInEdit) {
  let pointsStr = points.toString();
  let paddedPoints = paddy(pointsStr, 6);
  paddedPoints = paddedPoints.replaceAt(characterIndexInEdit, inputValue);
  return paddedPoints;
}

export function formatSeconds(inputValue, points, characterIndexInEdit) {
  let pointsStr = points.toString();
  let paddedPoints = paddy(pointsStr, 6);

  let charactersToManipulate = paddedPoints.substr(1, 2);

  //Tar bort gamla värdet (minutrarna som ska ersättas.)
  let value = paddedPoints.slice(0, 1) + paddedPoints.slice(3);

  if (characterIndexInEdit === 1) {
    //Skapar de nya uppdaterade minutrarna som ska ersätta de gamla.
    charactersToManipulate = charactersToManipulate.replaceAt(characterIndexInEdit, inputValue);
    //Alltid nolla i index 0 om användaren editerar index 1.
    //charactersToManipulate = charactersToManipulate.replaceAt(0, "0");

    //Editerar vi index 0 så flyttar vi värdet från index 0 till index 1 sen uppdaterar vi index 1 med det nya angivna värdet.
  } else if (characterIndexInEdit === 0) {
    let x = charactersToManipulate.slice(1);
    charactersToManipulate = charactersToManipulate.replaceAt(0, x);
    charactersToManipulate = charactersToManipulate.replaceAt(1, inputValue);
  }

  let minutesInt = parseInt(charactersToManipulate, 10);
  //Om minutrarna är över 59 så returnerar vi points tillbaka Vi skickar inte tillbaka några uppdaterade värden eftersom
  //användarna inte får skriva minuter som överstiger 59 minuter.
  if (minutesInt > 59) {
    return null;
  }

  let res = "000000";
  //Lägger till de nya uppdaterade minutrarna (updatedMinutes).
  res = [value.slice(0, 1), charactersToManipulate, value.slice(1)].join('');
  res = parseInt(res);
  return res;
}


export function formatHundreths(inputValue, points, characterIndexInEdit) {
  let pointsStr = points.toString();
  let paddedPoints = paddy(pointsStr, 6);

  let charactersToManipulate = paddedPoints.substr(3);

  //Om användaren anger samma siffra en fjärde gång returnerar vi null tillbaka. 
  //Se anropande funktion för hur detta null-värde tas om hand.
  if(charactersToManipulate.charAt(0) === inputValue 
  && charactersToManipulate.charAt(1) === inputValue
  && charactersToManipulate.charAt(2) === inputValue) {
    return null;
  }

  //Tar bort gamla värdet (hundradelarna som ska ersättas.)
  let value = paddedPoints.slice(0, 3);

  if (characterIndexInEdit === 2) {
    //Skapar de nya uppdaterade hundradelarna som ska ersätta de gamla.
    charactersToManipulate = charactersToManipulate.replaceAt(characterIndexInEdit, inputValue);
    //Alltid nolla i index 1 och 0 om användaren editerar index 2.
    //charactersToManipulate = charactersToManipulate.replaceAt(0, "0");
    //charactersToManipulate = charactersToManipulate.replaceAt(1, "0");

  } else if (characterIndexInEdit === 1) {
    //Editerar vi index 1 så flyttar vi värdet åt vänster från index 2 till index 1 sen uppdaterar vi index 2 med det nya angivna värdet.
    let charToMove = charactersToManipulate.slice(2);
    charactersToManipulate = charactersToManipulate.replaceAt(1, charToMove);
    charactersToManipulate = charactersToManipulate.replaceAt(2, inputValue);

  } else if (characterIndexInEdit === 0) {
     //Editerar vi index 0 så flyttar vi värdena åt vänster från index 1 till index 0 samt index 2 till index 1
     //sen uppdaterar vi index 2 med det nya angivna värdet.
    let charToMove1 = charactersToManipulate.slice(1, 2);
    charactersToManipulate = charactersToManipulate.replaceAt(0, charToMove1);

    let charToMove2 = charactersToManipulate.slice(2, 3);
    charactersToManipulate = charactersToManipulate.replaceAt(1, charToMove2);

    charactersToManipulate = charactersToManipulate.replaceAt(2, inputValue);
  }

  let res = "000000";
  res = value + charactersToManipulate;
  res = parseInt(res);
  return res;
}

export function getMinutes(points) {
  let value = paddy(points, 6);
  value = value.substr(0, 1);
  return value;
}

export function getSeconds(points) {
  let value = paddy(points, 6);
  value = value.substr(1, 2);
  return value;
}

export function getHundreths(points) {
  let value = paddy(points, 6);
  const hundreths = value.toString().slice(-3);
  return hundreths;
}

export function paddy(num, padlen, padchar) {
  var pad_char = typeof padchar !== 'undefined' ? padchar : '0';
  var pad = new Array(1 + padlen).join(pad_char);
  return (pad + num).slice(-pad.length);
}