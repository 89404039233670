import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({

  button: {
    color: '#FFF',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    }
  },
  cancelButton: {
    color: '#509CF5'
  },
  formControl: {
    margin: '5px 5px 5px 0px'
  },
  myComponent: {
    "& .MuiIconButton-root": {
      padding: 0
    }
  }
});

class MovePlayerDialog extends React.Component {


  constructor() {
    super()

    this.state = {
      selectedTeamId: ''
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };


  handleInputChange = (e) => {

    this.props.onInputChange(e.target.value);

  }

  handleClose = (e) => {

    this.props.onClose();

  }

  handleMove = () => {

    this.props.onMove(this.state.selectedTeamId);
  }

  handleKeyPress = (event) => {

    if (event.key === 'Enter') {
    /*   var newEvent = Object.assign({}, this.state);
      newEvent.created = firebase.firestore.Timestamp.fromDate(new Date());
      newEvent.isDividingEvent = false;
      this.props.onAdd(newEvent); */
    }
  }

  render() {

    const { classes } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>

          <DialogContentText style={{ marginTop: 30 }}>
            Flytta spelare till:
          </DialogContentText>

          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              aria-label="calculationFormat"
              name="calculationFormat"
              className={classes.group}
              value={this.state.selectedTeamId}
              onChange={this.handleChange('selectedTeamId')}
            >
              {this.props.teams.map(team => {
                return (
                  <FormControlLabel 
                    key={team.id}
                    value={team.id}
                    style={{paddingTop: 3}}
                    control={<Radio style={{ paddingTop: 3, paddingBottom: 0 }} />}
                    label={team.name} />
                );
              })}
            </RadioGroup>
          </FormControl>

        </DialogContent>
        <DialogActions>
          <Button className={classes.cancelButton} onClick={() => this.handleClose()}>
            Avbryt
          </Button>
          <Button className={classes.button} onClick={() => this.handleMove()}>
            Spara
            </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(MovePlayerDialog);