import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import firebase, { db } from '../firebase/firebase';
import { AuthContext } from '../firebase/authContext';
import ProgressIndicator from '../components/ProgressIndicator';
import NanoId from 'nanoid';
import TeamsCard from '../components/TeamsCard';
import { calculatePointsAndPlacements, calculateEventTeamPoints } from '../common/pointsCalculations';
import { findIndex, orderBy, cloneDeep, sumBy } from 'lodash';
import AlertDialog from '../components/AlertDialog';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as Routes from '../constants/routes';
import * as constants from "../common/constants";
import { MdGroupAdd } from 'react-icons/md';
import CreateTeamDialogEditTeams from '../components/CreateTeamDialogEditTeams';
import ConfirmDialog from '../components/ConfirmDialog';
import MovePlayerDialog from '../components/MovePlayerDialog';
import EditPlayerDialog from '../components/CreateItemDialog';
import EditTeamDialog from '../components/CreateItemDialog';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getEventFormat, getCategory } from '../common/helpers';
import TextField from '@material-ui/core/TextField';
import OtherInfoInput from '../components/OtherInfoInput';
import { BlockPicker } from 'react-color'

const styles = theme => ({
  root: {
    flexgrow: 1,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  displayLineBreak: {
    whiteSpace: 'pre-line'
  },
  gridItem: {
    marginTop: 5
  },
  icon: {
    marginLeft: 5
  },
  cardTeam: {
    width: '100%',
    border: '2px solid #509CF5',
    backgroundColor: '#FFF'
  },
  chip: {
    marginRight: theme.spacing.unit / 2,
    marginTop: theme.spacing.unit / 2,
    backgroundColor: '#e6e6e6'
  },
  TypographyTeamName: {
    fontSize: 24,
  },
  grow: {
    flexGrow: 1,
  },
  buttonColors: {
    border: '0px solid #509CF5',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    }
  },
  buttonAddPlayer: {
    fontSize: '0.9em',
    color: '#FFF',
    margin: 'auto',
  }
});

class AdminTeams extends React.Component {

  constructor() {
    super()
    this.onEventNameChange = this.onEventNameChange.bind(this);
    this.handleAddPlayer = this.handleAddPlayer.bind(this);
    this.handleDeletePlayer = this.handleDeletePlayer.bind(this);
    this.toggleAlertDialog = this.toggleAlertDialog.bind(this);
    this.handleOpenTeamDialog = this.handleOpenTeamDialog.bind(this);
    this.handleTeamNameChange = this.handleTeamNameChange.bind(this);
    this.handleAddTeam = this.handleAddTeam.bind(this);
    this.handleDeleteTeam = this.handleDeleteTeam.bind(this);
    this.toggleDeleteTeamDialog = this.toggleDeleteTeamDialog.bind(this);
    this.showMovePlayerDialog = this.showMovePlayerDialog.bind(this);

    this.showEditPlayerDialog = this.showEditPlayerDialog.bind(this);
    this.handlePlayerNameChange = this.handlePlayerNameChange.bind(this);
    this.handleCloseEditPlayerDialog = this.handleCloseEditPlayerDialog.bind(this);
    this.handleUpdatePlayer = this.handleUpdatePlayer.bind(this);

    this.showEditTeamDialog = this.showEditTeamDialog.bind(this);
    this.handleTeamNameUpdateChange = this.handleTeamNameUpdateChange.bind(this);
    this.handleCloseEditTeamDialog = this.handleCloseEditTeamDialog.bind(this);
    this.handleUpdateTeam = this.handleUpdateTeam.bind(this);

    this.handleOtherInfoChange = this.handleOtherInfoChange.bind(this);
    this.handleOtherInfoBlur = this.handleOtherInfoBlur.bind(this);
    this.handleEventStartDateTimeChange = this.handleEventStartDateTimeChange.bind(this);

    this.handleChangeEventColor = this.handleChangeEventColor.bind(this);

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      teams: null,
      points: null,
      page: 0,
      rowsPerPage: 10,
      selectedTeamIndex: null,
      selectedPlayer: null,
      deleteTeamDialogIsOpen: false,
      movePlayerDialogIsOpen: false,
      editPlayerDialogStatus: false,
      editTeamDialogStatus: false,
      playerInEdit: null,
      teamInEdit: null,
      newEvent: {
        name: "",
        created: null
      },
      newTeam: {
        name: ""
      },
      alertDialogIsOpen: false,
      addTeamDialogStatus: false,
      otherInfo: "",
      eventColor: "",
      eventStartDateTime: ""
    };
  }

  onEventNameChange(value) {
    var newEvent = Object.assign({}, this.state.newEvent);
    newEvent.name = value;
    this.setState({ newEvent });
  }

  toggleAlertDialog() {
    this.setState({ alertDialogIsOpen: !this.state.alertDialogIsOpen });
  }

  handleDeletePlayer = (playerId, teamId, teams) => {

    const customer = this.context.customer;
    return new Promise(resolve => {

      if (typeof teams === "undefined" || teams === null) {
        teams = JSON.parse(JSON.stringify(this.state.teams));
      }

      const teamIndex = findIndex(teams, function (o) { return o.id === teamId });
      const playerIndex = findIndex(teams[teamIndex].players, function (o) { return o.id === playerId });

      var player = teams[teamIndex].players[playerIndex];

      if (typeof player === "undefined" || teams[teamIndex].players.length < 3) {
        this.toggleAlertDialog();
        resolve(false);
        return;
      }

      teams[teamIndex].players.splice(playerIndex, 1);

      if (teams[teamIndex].totalPoints !== null && teams[teamIndex].players.length > 0) {
        //Sätt placeringspoäng/poäng/placering på spelarna samt sortera spelarna efter placeringspoäng.
        teams[teamIndex].players[0].points.forEach((event, eventIndex) => {
          if (teams[teamIndex].points[eventIndex].points !== null) {
            var calculationCategory = teams[teamIndex].points[eventIndex].calculationCategory;
            teams[teamIndex].players = calculatePointsAndPlacements(teams[teamIndex].players, eventIndex, calculationCategory, false, customer);
          }
        });
      }
      if (teams[teamIndex].players.length > 0) {
        //Sätt placeringspoäng/poäng/placering på lagen samt sortera lagen efter placeringspoäng.
        teams[teamIndex].players[0].points.forEach((event, eventIndex) => {
          if (teams[teamIndex].points[eventIndex].points !== null) {
            //Sätter lagets totala antal poäng för grenen.
            var calculationCategory = teams[teamIndex].points[eventIndex].calculationCategory;
            teams[teamIndex].points[eventIndex].points = calculateEventTeamPoints(teams, teamIndex, eventIndex, calculationCategory);
            teams = calculatePointsAndPlacements(teams, eventIndex, calculationCategory, true, customer);
          }
        });
      }

      this.setState({ teams: teams });

      var gameId = this.props.match.params.gameId;
      let customerId = this.context.customer.id;

      var docRef = db.collection("customers").doc(customerId)
        .collection('games').doc(gameId);

      docRef.update({
        updated: firebase.firestore.Timestamp.fromDate(new Date()),
        teams: teams
      }).then(function () {
        resolve(true);
      }).catch(function (error) {
        resolve(false);
        console.log("Error getting document:", error);
      });
    });
  }

  async handleAddPlayer(playerName, teamIndex, existingPlayer, teams) {

    return new Promise(resolve => {

      if (typeof teams === "undefined" || teams === null) {
        teams = JSON.parse(JSON.stringify(this.state.teams));
      }

      let player = teams[0].players[0];

      if (typeof player === "undefined" || player === null) {
        return;
      }

      var newPlayer = {
        id: NanoId(),
        name: playerName,
        totalPlacementpoints: null,
        totalPoints: null,
        placement: null,
        teamId: teams[teamIndex].id,
        created: firebase.firestore.Timestamp.fromDate(new Date())
      };

      var points = [];
      player.points.forEach(point => {
        var newPoint = {
          id: NanoId(),
          eventId: point.eventId,
          eventName: point.eventName,
          points: null,
          placementpoints: null,
          isDividingEvent: point.isDividingEvent,
          calculationFormat: point.calculationFormat,
          calculationCategory: point.calculationCategory
        };
        points.push(newPoint);
      });

      newPlayer.points = points;

      //Om vi redan har en existerande spelare som vi tex vill flytta via denna handleAddPlayer-funktionen.
      if (typeof existingPlayer !== "undefined" && existingPlayer !== null) {
        newPlayer = existingPlayer;
      }

      teams[teamIndex].players.push(newPlayer);

      teams = this.calculatePointsForTeams(teams);

      var gameId = this.props.match.params.gameId;
      let customerId = this.context.customer.id;
      var docRef = db.collection("customers").doc(customerId)
        .collection('games').doc(gameId);
      docRef.update({
        updated: firebase.firestore.Timestamp.fromDate(new Date()),
        teams: teams
      }).then(function () {
        resolve(teams);
      }).catch(function (error) {
        console.log("Error getting document:", error);
        resolve(false);
      });
      resolve(teams);
    });
  }

  componentDidMount() {
    var gameId = this.props.match.params.gameId;
    this.fetchTeams(gameId);
    this.fetchEvents();
  }

  componentWillMount() {
    document.body.classList.remove("bgTexture");
  }

  handleOtherInfoChange(value) {
    this.setState({
      otherInfo: value
    });
  }

  handleEventStartDateTimeChange = date => {
    const datetime = date.target.value;
    this.setState({
      eventStartDateTime: datetime
    });
    var gameId = this.props.match.params.gameId;
    let customerId = this.context.customer.id;
    var docRef = db.collection("customers").doc(customerId)
      .collection('games').doc(gameId);
    docRef.update({
      updated: firebase.firestore.Timestamp.fromDate(new Date()),
      eventStartDateTime: datetime
    }).then(function () {
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }

  handleOtherInfoBlur() {
    const otherInfo = this.state.otherInfo;
    var gameId = this.props.match.params.gameId;
    let customerId = this.context.customer.id;
    var docRef = db.collection("customers").doc(customerId)
      .collection('games').doc(gameId);
    docRef.update({
      updated: firebase.firestore.Timestamp.fromDate(new Date()),
      otherInfo: otherInfo
    }).then(function () {
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }

  handleChangeEventColor = (color) => {
    this.setState({ eventColor: color.hex });
    var gameId = this.props.match.params.gameId;
    let customerId = this.context.customer.id;
    var docRef = db.collection("customers").doc(customerId)
      .collection('games').doc(gameId);
    docRef.update({
      updated: firebase.firestore.Timestamp.fromDate(new Date()),
      eventColor: color.hex
    }).then(function () {
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  };

  fetchEvents() {

    let points = [];

    let customerId = this.context.customer.id;

    db.collection("customers").doc(customerId).collection('events').get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {

        let pointObj = {
          id: null,
          eventId: doc.id,
          eventName: doc.data().name,
          points: null,
          placementpoints: null,
          isDividingEvent: doc.data().isDividingEvent,
          calculationFormat: doc.data().calculationFormat,
          unit: doc.data().unit,
          calculationCategory: doc.data().calculationCategory,
          placementCalculation: doc.data().placementCalculation,
          closestTo: doc.data().closestTo,
          diffToClosest: doc.data().closestTo,
          roundPoints: doc.data().roundPoints
        }
        points.push(pointObj);
      });

      this.setState({
        checkedPoints: points,
        points: points
      });
    });
  }

  fetchTeams(gameId) {

    var self = this;
    let customerId = this.context.customer.id;

    var docRef = db.collection("customers").doc(customerId)
      .collection('games').doc(gameId);

    docRef.get().then(function (doc) {
      if (doc.exists) {
        var teams = doc.data().teams;
        const otherInfo = doc.data().otherInfo;
        const eventColor = doc.data().eventColor;
        const eventStartDateTime = doc.data().eventStartDateTime;
        self.setState({ teams: teams, otherInfo: otherInfo, eventColor: eventColor, eventStartDateTime });
      }
    });

    docRef.onSnapshot(function (doc) {
      if (doc.exists) {
        var teams = doc.data().teams;

        teams.forEach(team => {
          team.players = orderBy(team.players, function (o) { return o.created.seconds });
        });

        teams = orderBy(teams, function (o) { return o.created });

        self.setState({
          teams: teams,
        });
      } else {
        console.log("No such document!");
      }
    });
  }


  getChecked(point) {

    const eventIndex = findIndex(this.state.teams[0].points, function (o) { return o.eventId === point.eventId });
    if (eventIndex > -1) {
      return true;
    }
    return false;
  }

  getStartTime(point) {

    const eventIndex = findIndex(this.state.teams[0].points, function (o) { return o.eventId === point.eventId });
    if (eventIndex > -1) {
      const startTime = this.state.teams[0].points[eventIndex].startTime;
      if (typeof startTime !== "undefined") {
        return startTime;
      }
    }
    return "00:00";
  }


  handleTimeChange = date => {

    const time = date.target.value;
    const eventId = date.currentTarget.id;
    var teams = JSON.parse(JSON.stringify(this.state.teams));
    const eventIndex = findIndex(teams[0].points, function (o) { return o.eventId === eventId });
    if (eventIndex > -1) {
      teams.forEach(team => {
        team.points[eventIndex].startTime = time;
        team.players.forEach(player => {
          player.points[eventIndex].startTime = time;
        });
      });
    }

    var gameId = this.props.match.params.gameId;
    let customerId = this.context.customer.id;

    var docRef = db.collection("customers").doc(customerId)
      .collection('games').doc(gameId);

    docRef.update({
      updated: firebase.firestore.Timestamp.fromDate(new Date()),
      teams: teams
    }).then(function () {
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });

  };

  handleToggleEvents(point) {

    var teams = JSON.parse(JSON.stringify(this.state.teams));

    const eventIndex = findIndex(teams[0].points, function (o) { return o.eventId === point.eventId });

    //Om eventIndex är större än -1 så finns grenen och den ska tas bort.
    if (eventIndex > -1) {
      teams.forEach(team => {
        team.points.splice(eventIndex, 1);
        team.players.forEach(player => {
          player.points.splice(eventIndex, 1);
        });
      });
    }  //Annars ska den grenen läggas till.
    else {
      point.id = NanoId();
      teams.forEach(team => {
        let newPoint = JSON.parse(JSON.stringify(point));
        newPoint.id = NanoId();
        team.points.push(newPoint);
        team.players.forEach(player => {
          newPoint = JSON.parse(JSON.stringify(point));
          newPoint.id = NanoId();
          player.points.push(newPoint);
        });
      });
    }

    this.setState({
      teams: teams
    });

    teams = this.calculatePointsForTeams(teams);

    const customer = this.context.customer;
    var gameId = this.props.match.params.gameId;

    var docRef = db.collection("customers").doc(customer.id)
      .collection('games').doc(gameId);

    docRef.update({
      updated: firebase.firestore.Timestamp.fromDate(new Date()),
      teams: teams
    }).then(function () {
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  };

  calculatePointsForTeams(teams) {
    const customer = this.context.customer;
    console.log(this.context.customer)
    teams.forEach((team, teamIndex) => {
      if (team.players.length > 0) {
        //Sätt placeringspoäng/poäng/placering på spelarna samt sortera spelarna efter placeringspoäng.
        team.players[0].points.forEach((event, eventIndex) => {
          if (team.points[eventIndex].points !== null) {
            var calculationCategory = team.points[eventIndex].calculationCategory;
            team.players = calculatePointsAndPlacements(team.players, eventIndex, calculationCategory, false, customer);
          }
        });

        //Sätt placeringspoäng/poäng/placering på lagen samt sortera lagen efter placeringspoäng.
        team.players[0].points.forEach((event, eventIndex) => {
          let teamEventPoints = sumBy(team.players, function (o) { return o.points[eventIndex].points; });
          if (teamEventPoints > 0) {
            //Sätter lagets totala antal poäng för grenen.
            const calculationCategory = team.points[eventIndex].calculationCategory;
            team.points[eventIndex].points = calculateEventTeamPoints(teams, teamIndex, eventIndex, calculationCategory);
            teams = calculatePointsAndPlacements(teams, eventIndex, calculationCategory, true, customer);
          }
        });
        //Här sätts teamets totala poäng.
        let totalPoints = sumBy(team.points, function (o) { return o.points; });
        team.totalPoints = totalPoints;
      }
    });
    return teams;
  }

  getEventCategoryDescription(calculationCategory) {
    if (calculationCategory === constants.calculationCategories.closest.value) {
      return 'Närmast poäng';
    } else if (calculationCategory === constants.calculationCategories.lowest.value) {
      return 'Lägsta poäng';
    }
    return 'Högsta poäng';
  }

  handleOpenTeamDialog() {
    this.setState({ addTeamDialogStatus: true });
  };

  handleCloseEditPlayerDialog = () => {
    this.setState({ editPlayerDialogStatus: false });
  };

  handleCloseEditTeamDialog = () => {
    this.setState({ editTeamDialogStatus: false });
  };

  handleTeamNameChange(value) {
    let newTeam = Object.assign({}, this.state.newTeam);
    newTeam.name = value;
    this.setState({ newTeam });
  }

  handlePlayerNameChange(value) {
    let player = Object.assign({}, this.state.playerInEdit);
    player.name = value;
    this.setState({ playerInEdit: player });
  }

  handleTeamNameUpdateChange(value) {
    let team = Object.assign({}, this.state.teamInEdit);
    team.name = value;
    this.setState({ teamInEdit: team });
  }

  handleAddTeam() {

    let newTeam = cloneDeep(this.state.newTeam);
    newTeam.id = NanoId();
    newTeam.players = [];
    newTeam.totalPlacementpoints = null;
    newTeam.totalPoints = null;
    newTeam.placement = null;

    let clonedPoints = cloneDeep(this.state.teams[0].points);

    //Clonar poängen från första laget. Sätter poängvärden till null eftersom ett nyskapat lag alltid har null i initiala poäng.
    clonedPoints.forEach(point => {
      point.id = NanoId();
      point.placementpoints = null;
      point.points = null;
      point.roundPoints = [];
    });

    newTeam.points = clonedPoints;

    let _this = this;
    let teams = [...this.state.teams, newTeam]
    this.setState({
      teams: teams,
      addTeamDialogStatus: false
    });

    //Sparar till databasen.
    var gameId = _this.props.match.params.gameId;
    let customerId = _this.context.customer.id;
    var docRef = db.collection("customers").doc(customerId)
      .collection('games').doc(gameId);
    docRef.update({
      updated: firebase.firestore.Timestamp.fromDate(new Date()),
      teams: teams
    }).then(function () {
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });

  };

  handleCloseTeamDialog = () => {
    let newTeam = Object.assign({}, this.state.newTeam);
    newTeam.name = "";
    this.setState({ newTeam });
    this.setState({ addTeamDialogStatus: false });
  };

  toggleDeleteTeamDialog() {
    this.setState({ deleteTeamDialogIsOpen: !this.state.deleteTeamDialogIsOpen });
  }

  handleUpdatePlayer() {

    let teams = Object.assign([], this.state.teams);
    //Itererar lagen och försöker hitta spelaren som ska uppdateras.
    teams.map(team => {
      var playerToUpdate = team.players.find(x => x.id === this.state.playerInEdit.id);
      if (typeof playerToUpdate !== "undefined") {
        playerToUpdate.name = this.state.playerInEdit.name;
      }
      return team;
    });

    this.setState({
      teams: teams,
      playerInEdit: null,
      editPlayerDialogStatus: false
    });

    var gameId = this.props.match.params.gameId;
    let customerId = this.context.customer.id;

    var docRef = db.collection("customers").doc(customerId)
      .collection('games').doc(gameId);

    docRef.update({
      updated: firebase.firestore.Timestamp.fromDate(new Date()),
      teams: teams
    }).then(function () {
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  };

  handleUpdateTeam() {

    let teams = Object.assign([], this.state.teams);
    //Itererar lagen och försöker hitta spelaren som ska uppdateras.
    teams.map(team => {
      if (team.id === this.state.teamInEdit.id) {
        team.name = this.state.teamInEdit.name;
      }
      return team;
    });

    this.setState({
      teams: teams,
      teamInEdit: null,
      editTeamDialogStatus: false
    });

    var gameId = this.props.match.params.gameId;
    let customerId = this.context.customer.id;

    var docRef = db.collection("customers").doc(customerId)
      .collection('games').doc(gameId);

    docRef.update({
      updated: firebase.firestore.Timestamp.fromDate(new Date()),
      teams: teams
    }).then(function () {
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });

  }

  handleDeleteTeam = () => {
    var teams = Object.assign([], this.state.teams);

    //Det måste minst finnas 1 lag.
    if (teams.length > 1) {
      teams.splice(this.state.selectedTeamIndex, 1);

      this.setState({
        teams: teams,
        deleteTeamDialogIsOpen: false
      });

      var gameId = this.props.match.params.gameId;
      let customerId = this.context.customer.id;

      var docRef = db.collection("customers").doc(customerId)
        .collection('games').doc(gameId);

      docRef.update({
        updated: firebase.firestore.Timestamp.fromDate(new Date()),
        teams: teams
      }).then(function () {
      }).catch(function (error) {
        console.log("Error getting document:", error);
      });
    } else {
      this.setState({
        deleteTeamDialogIsOpen: false
      });
    }
  }

  showDeleteTeamDialog = (index) => {
    this.setState({
      deleteTeamDialogIsOpen: true,
      selectedTeamIndex: index
    });
  }

  showMovePlayerDialog = (player) => {
    this.setState({
      movePlayerDialogIsOpen: true,
      selectedPlayer: player
    });
  }

  showEditPlayerDialog(player) {
    this.setState({
      editPlayerDialogStatus: true,
      playerInEdit: player
    });
  };

  showEditTeamDialog(team) {
    this.setState({
      editTeamDialogStatus: true,
      teamInEdit: team
    });
  };

  handleMovePlayer = (moveToTeamId) => {

    let teams = cloneDeep(this.state.teams);
    let player = cloneDeep(this.state.selectedPlayer);
    let fromTeamId = player.teamId;
    let _this = this;

    //Hämtar laget som användaren ska lägga till i.
    const teamIndex = findIndex(teams, function (o) { return o.id === moveToTeamId });
    player.teamId = teams[teamIndex].id;

    //Tar bort användaren från ursprungliga laget och lägger till spelaren i det nya laget.
    const fromTeamIndex = findIndex(teams, function (o) { return o.id === fromTeamId });
    _this.handleDeletePlayer(player.id, teams[fromTeamIndex].id, teams).then(function (deleteRes) {
      if (deleteRes) {
        _this.handleAddPlayer(player.name, teamIndex, player, teams).then(function () {
        });
      }
    });

    this.setState({
      movePlayerDialogIsOpen: false,
      selectedPlayer: player,
    });

  }

  handleCloseMovePlayerDialog = () => {
    this.setState({
      movePlayerDialogIsOpen: false,
      selectedPlayer: null
    });
  }


  static contextType = AuthContext;
  render() {

    const { classes } = this.props;
    const { teams, points } = this.state;

    if (teams !== null && points !== null) {

      const now = new Date();
      const offset = now.getTimezoneOffset();
      const adjustedDate = new Date(now.getTime() - offset * 60000);
      let formattedDateTime = adjustedDate.toISOString().slice(0, 16);

      if (this.state.eventStartDateTime !== "") {
        formattedDateTime = this.state.eventStartDateTime;
      }

      return (
        <div className={classes.root}>
          <AlertDialog
            open={this.state.alertDialogIsOpen}
            title={'Meddelande'}
            body={'Ett lag får minst innehålla två spelare. Lägg först till en ny spelare innan du kan ta bort denna spelaren.'}
            toggleAlertDialog={this.toggleAlertDialog} />

          <CreateTeamDialogEditTeams
            dialogStatus={this.state.addTeamDialogStatus}
            dialogTitle={teams.length < 1 ? "Skapa sällskap / lag" : "Skapa lag"}
            dialogDesc={teams.length < 1 ?
              <span style={{ fontSize: 14 }}>Skriv namn på sällskapet alternativt lagets namn om flera lag tävlar mot varandra</span> :
              <span style={{ fontSize: 14 }}>Skriv namn på lag nummer {teams.length + 1}</span>
            }
            dialogLabel={teams.length < 1 ? 'Sällskapet / lagets namn:' : 'Lagets namn:'}
            onInputChange={this.handleTeamNameChange}
            onClose={this.handleCloseTeamDialog}
            onAdd={this.handleAddTeam}
            addLabel="Skapa sällskap / lag" />

          <div style={{ display: 'flex' }}>
            <Typography variant="h6" gutterBottom>
              Redigera lag och grenar
            </Typography>
            <Typography variant="h6" gutterBottom style={{ textAlign: 'right' }} className={classes.grow}>
            </Typography>
            <Button className={classes.buttonColors + ' ' + classes.buttonAddPlayer} onClick={() => this.props.history.push({ pathname: '/activeteams' })}>
              Pågående omgångar
            </Button>
            &nbsp;
            <Button className={classes.buttonColors + ' ' + classes.buttonAddPlayer} onClick={() => this.props.history.push(Routes.PLAY + "/" + this.props.match.params.gameId)}>
              Återuppta
            </Button>
          </div>

          <EditPlayerDialog
            dialogStatus={this.state.editPlayerDialogStatus}
            dialogTitle={"Ändra namn på spelare"}
            dialogDesc={<span style={{ fontSize: 14 }}>Skriv namn på spelaren</span>
            }
            dialogLabel={'Spelarens namn:'}
            onInputChange={this.handlePlayerNameChange}
            defaultValue={this.state.playerInEdit === null ? '' : this.state.playerInEdit.name}
            onClose={this.handleCloseEditPlayerDialog}
            onSubmit={this.handleUpdatePlayer}
            addLabel="Spara" />

          <EditTeamDialog
            dialogStatus={this.state.editTeamDialogStatus}
            dialogTitle={"Ändra namn på laget"}
            dialogDesc={<span style={{ fontSize: 14 }}>Skriv namn på laget</span>
            }
            dialogLabel={'Lagets namn:'}
            onInputChange={this.handleTeamNameUpdateChange}
            defaultValue={this.state.teamInEdit === null ? '' : this.state.teamInEdit.name}
            onClose={this.handleCloseEditTeamDialog}
            onSubmit={this.handleUpdateTeam}
            addLabel="Spara" />

          <ConfirmDialog
            open={this.state.deleteTeamDialogIsOpen}
            title={'Ta bort lag'}
            body={'Vill du verkligen ta bort laget?'}
            toggleDialog={this.toggleDeleteTeamDialog}
            confirm={this.handleDeleteTeam} />

          <MovePlayerDialog
            open={this.state.movePlayerDialogIsOpen}
            title={'Flytta spelare'}
            body={''}
            showMovePlayerDialog={this.showMovePlayerDialog}
            teams={this.state.teams}
            onMove={this.handleMovePlayer}
            onClose={this.handleCloseMovePlayerDialog} />

          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} className={classes.gridItem}>
              {teams.map((team, index) =>
                <TeamsCard
                  key={team.id}
                  team={team}
                  index={index}
                  handleAddPlayer={this.handleAddPlayer}
                  handleDeletePlayer={this.handleDeletePlayer}
                  showDeleteTeamDialog={this.showDeleteTeamDialog}
                  showMovePlayerDialog={this.showMovePlayerDialog}
                  showEditPlayerDialog={this.showEditPlayerDialog}
                  showEditTeamDialog={this.showEditTeamDialog}
                />
              )}

              <Button className={classes.buttonColors + ' ' + classes.buttonAddPlayer}
                style={{ marginTop: '12px' }}
                onClick={this.handleOpenTeamDialog}>
                <Typography variant="h5">&nbsp;
                  <MdGroupAdd style={{ verticalAlign: 'baseline', paddingTop: 5, color: 'white' }} />
                </Typography>
                &nbsp;&nbsp;Lägg till lag&nbsp;&nbsp;&nbsp;
              </Button>

            </Grid>


            <Grid
              container
              spacing={8}
              direction="row"
              justifycontent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} >
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Välj</TableCell>
                        <TableCell>Gren</TableCell>
                        <TableCell>Format</TableCell>
                        <TableCell>Kategori</TableCell>
                        <TableCell>Enhet</TableCell>
                        <TableCell>Starttid</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {points.map(point => (
                        <TableRow key={point.eventId}>
                          <TableCell component="th" scope="row" padding="checkbox">
                            <Checkbox
                              checked={this.getChecked(point)}
                              onChange={this.handleToggleEvents.bind(this, point)}
                            />
                          </TableCell>
                          <TableCell>
                            {point.eventName}
                          </TableCell>
                          <TableCell>{getEventFormat(point)}</TableCell>
                          <TableCell>{getCategory(point)}</TableCell>
                          <TableCell>{`${typeof point.unit === "undefined" ? 'p' : point.unit}`}</TableCell>
                          <TableCell>
                            <TextField
                              id={point.eventId}
                              disabled={!this.getChecked(point)}
                              label=""
                              type="time"
                              value={this.getStartTime(point)}
                              onChange={this.handleTimeChange}
                              className={classes.textField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={8} className={classes.gridItem}>
                <OtherInfoInput onOtherInfoChange={this.handleOtherInfoChange} content={this.state.otherInfo} onBlur={this.handleOtherInfoBlur} />
              </Grid>

              <Grid item xs={12} sm={4} className={classes.gridItem}>
                <Typography>Starttid</Typography>
                <TextField
                  id="datetime-local"
                  label=""
                  onChange={this.handleEventStartDateTimeChange}
                  type="datetime-local"
                  defaultValue={formattedDateTime}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Typography>Färgkod</Typography><br />
                <BlockPicker
                  color={this.state.eventColor}
                  onChangeComplete={this.handleChangeEventColor}
                  colors={['#e6194B', '#f58231', '#ffe119', '#bfef45', '#3cb44b', '#42d4f4', '#4363d8', '#911eb4', '#f032e6', '#a9a9a9',]}
                />
              </Grid>

            </Grid>
          </Grid>
        </div>
      );
    }
    return (<ProgressIndicator />)
  }
}

export default withStyles(styles)(AdminTeams);
