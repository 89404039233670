import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import firebase, { db } from '../firebase/firebase';
import ProgressIndicator from '../components/ProgressIndicator';
import KeypadDialog from '../components/KeypadDialog';
import ScoreTable from '../components/ScoreTable';
import ScoreTableNoButtons from '../components/ScoreTableNoButtons';
import { findIndex, orderBy } from 'lodash';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { AuthContext } from '../firebase/authContext';
import PrintIcon from '@material-ui/icons/Print';
import { calculatePointsAndPlacements, calculateEventTeamPoints } from '../common/pointsCalculations';
import { FaHome } from "react-icons/fa";
import * as Routes from '../constants/routes';
import PrintDialog from '../components/PrintDialog';
import { Typography } from '@material-ui/core';
import logo from '../img/logo.svg';
import Hidden from '@material-ui/core/Hidden';
import Switch from 'react-switch';
import { sortPlayers } from '../common/helpers';

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  table: {
    minWidth: 700,
  },
  button: {
    color: '#FFF'
  },
  tab: {
    minWidth: '80px'
  },
  labelContainer: {
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  label: {
    fontSize: '0.7rem'
  },
  title: {
    color: '#FFF',
    marginLeft: 10,
  },
});

class Play extends React.Component {

  constructor(props) {
    super(props);
    this.handleOpenKeypadDialog = this.handleOpenKeypadDialog.bind(this);
    this.handleCloseKeypadDialog = this.handleCloseKeypadDialog.bind(this);
    this.togglePrintDialog = this.togglePrintDialog.bind(this);
    this.handleToggleSorting = this.handleToggleSorting.bind(this);

    this.state = {
      tabs: [],
      teams: null,
      individualPlayers: [],
      value: null,
      flashingPointId: "",
      printDialogIsOpen: false,
      keypad: {
        teamId: "",
        playerId: "",
        playerName: "",
        eventName: "",
        eventId: "",
        pointsId: "",
        isEdit: false,
        dialogStatus: false,
        points: null,
        unit: 'p'
      },
      keypadInEditMode: false,
      sortingEnabled: true
    };
  }

  componentWillMount() {
    document.body.classList.remove("bgTexture");
  }

  print() {
    window.print();
  }

  togglePrintDialog() {
    this.setState({ printDialogIsOpen: !this.state.printDialogIsOpen });
  }

  showPrintDialog() {
    this.togglePrintDialog();
  }

  handlePrint = (checked, preview) => {
    this.props.history.push(Routes.PRINT, {
      items: this.getItemsForPrint(this.state.value),
      title: this.getTitleForPrint(this.state.value), includePodium: checked, preview: preview
    });
  }

  componentDidMount() {

    const customer = this.context.customer;
    var self = this;
    var gameId = this.props.match.params.gameId;

    var docRef = db.collection("customers").doc(customer.id)
      .collection('games').doc(gameId);

    docRef.get().then(function (doc) {

      if (doc.exists) {

        var teams = doc.data().teams;

        var sortingEnabled = doc.data().sortingEnabled;
        var tabs = [];
        var sorted = orderBy(teams, function (o) { return o.name });
        var individualPlayers = [];
        sorted.forEach(team => {

          team.players = sortPlayers(sortingEnabled, team.players, customer);

          let tab = {
            id: team.id,
            placement: team.placement,
            name: team.name
          }
          tabs.push(tab);
          individualPlayers = individualPlayers.concat(team.players);

        });

        if (teams.length > 1 && teams[0].totalPoints !== null) {
          //Räkna ut individella poäng. (Dessa lagras ej i db och utförs on-the-fly)
          individualPlayers = JSON.parse(JSON.stringify(individualPlayers))
          if (individualPlayers.length > 0) {
            individualPlayers[0].points.forEach((event, index) => {
              if (teams[0].points[index].points !== null) {
                individualPlayers = calculatePointsAndPlacements(individualPlayers, index, teams[0].points[index].calculationCategory, false, self.context.customer);
              }
            });
          }
        }

        self.setState({
          teams: teams,
          individualPlayers: individualPlayers,
          value: doc.data().teams[0].id,
          tabs: tabs,
          sortingEnabled: doc.data().sortingEnabled
        });
        self.handleToggleSorting(doc.data().sortingEnabled);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });

    docRef.onSnapshot(function (doc) {

      if (doc.exists) {
        var teams = doc.data().teams;

        var sortingEnabled = doc.data().sortingEnabled;
        var tabs = [];
        var sorted = orderBy(teams, function (o) { return o.name });

        var individualPlayers = [];

        sorted.forEach(team => {

          team.players = sortPlayers(sortingEnabled, team.players, customer);

          let tab = {
            id: team.id,
            placement: team.placement,
            name: team.name
          }

          tabs.push(tab);

          individualPlayers = individualPlayers.concat(team.players);

        });

        if (teams.length > 1 && teams[0].totalPoints !== null) {
          //Sätt placeringspoäng/poäng/placering individuellt för alla spelare oavsett lag
          //samt sortera spelarna efter placeringspoäng. (Dessa lagras ej i db och utförs on the fly)
          individualPlayers = JSON.parse(JSON.stringify(individualPlayers));
          if (individualPlayers.length > 0) {
            individualPlayers[0].points.forEach((event, index) => {
              if (teams[0].points[index].points !== null) {
                individualPlayers = calculatePointsAndPlacements(individualPlayers, index, teams[0].points[index].calculationCategory, false, self.context.customer);
              }
            });
          }
        }

        individualPlayers = sortPlayers(sortingEnabled, individualPlayers, customer);

        self.setState({
          teams: teams,
          individualPlayers: individualPlayers,
          tabs: tabs,
          sortingEnabled: sortingEnabled
        });

      } else {
        self.props.history.push(Routes.START);
      }
    });
  }

  handleCloseKeypadDialog() {
    var keypad = Object.assign({}, this.state.keypad);
    keypad.dialogStatus = false;
    this.setState({ keypad: keypad });
  };

  handleOpenKeypadDialog(teamId, playerId, playerName, point, points, isEdit, isEditingClosestTo) {

    var keypad = Object.assign({}, this.state.keypad);
    keypad.teamId = teamId;
    keypad.playerId = playerId;
    keypad.playerName = playerName;
    keypad.eventName = point.eventName;
    keypad.eventId = point.eventId;
    keypad.pointsId = point.id;
    keypad.points = points;
    keypad.roundPoints = point.roundPoints;
    keypad.calculationFormat = point.calculationFormat;
    keypad.unit = point.unit;

    if (typeof keypad.points === 'undefined') {
      keypad.points = null;
    }

    keypad.dialogStatus = true;
    keypad.isEditingClosestTo = isEditingClosestTo;
    keypad.title = point.eventName + ' - ' + playerName;
    if (isEditingClosestTo) {
      keypad.title = point.eventName + ' - Närmast antal poäng'
    }

    this.setState({
      keypad: keypad,
      keypadInEditMode: isEdit
    });
  };

  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  setKeypadInEditToFalse = () => {
    this.setState({ keypadInEditMode: false });
  }

  componentWillUnmount() {
    clearInterval(this.flashTimer);
  }

  getTitleForPrint(tabValue) {

    if (tabValue === 'individualScore') {
      return 'Individuellt resultat';
    }
    else if (tabValue === 'teamsScore') {
      return 'Lagresultat';
    }
    else {
      const index = findIndex(this.state.teams, function (o) { return o.id === tabValue });
      return this.state.teams[index].name;
    }
  }

  getItemsForPrint(tabValue) {

    if (tabValue === 'individualScore') {
      return this.state.individualPlayers;
    }
    else if (tabValue === 'teamsScore') {
      return this.state.teams;
    }
    else {
      const index = findIndex(this.state.teams, function (o) { return o.id === tabValue });
      return this.state.teams[index].players;
    }
  }


  handleSavePoints = (keypad, points, roundPoints) => {

    if (points === null) {
      keypad.points = 0;
    }
    else {
      keypad.points = points;
    }
  
    var teams = Object.assign([], this.state.teams);
    const eventIndex = findIndex(teams[0].players[0].points, function (o) { return o.eventId === keypad.eventId });
    const teamIndex = findIndex(teams, function (o) { return o.id === keypad.teamId });
    const playerIndex = findIndex(teams[teamIndex].players, function (o) { return o.id === keypad.playerId });
    const pointsIndex = findIndex(teams[teamIndex].players[playerIndex].points, function (o) { return o.id === keypad.pointsId });

    //Sparar roundpoints.
    teams[teamIndex].players[playerIndex].points[pointsIndex].roundPoints = roundPoints;

    teams[teamIndex].players[playerIndex].points[pointsIndex].points = parseFloat(keypad.points);
    //Sätt placeringspoäng/poäng/placering på spelarna samt sortera spelarna efter placeringspoäng.
    teams[teamIndex].players = calculatePointsAndPlacements(teams[teamIndex].players, eventIndex, teams[teamIndex].players[playerIndex].points[pointsIndex].calculationCategory, false, this.context.customer);

    //Sätter lagets totala antal genomsnittliga poäng för grenen.
    var calculationCategory = teams[teamIndex].points[eventIndex].calculationCategory;
    const calculationFormat = teams[teamIndex].points[eventIndex].calculationFormat;

    teams[teamIndex].points[eventIndex].points = calculateEventTeamPoints(teams, teamIndex, eventIndex, calculationCategory, calculationFormat);
    //Sätt placeringspoäng/poäng/placering på lagen samt sortera lagen efter placeringspoäng.
    teams = calculatePointsAndPlacements(teams, eventIndex, teams[teamIndex].points[eventIndex].calculationCategory, true, this.context.customer);

    keypad.dialogStatus = false;

    this.setState({
      flashingPointId: keypad.pointsId,
      keypad: keypad
    });

    var self = this;

    this.flashTimer = setTimeout(function () {

      self.setState({
        flashingPointId: '',
      });

    }, 4000);

    var gameId = this.props.match.params.gameId;
    let customerId = this.context.customer.id;
    var docRef = db.collection("customers").doc(customerId)
      .collection('games').doc(gameId);

    docRef.update({
      updated: firebase.firestore.Timestamp.fromDate(new Date()),
      teams: teams
    }).then(function () {
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }


  handleToggleSorting(checked) {

    //Sorterar spelarna inuti respektive team.
    var teams = Object.assign([], this.state.teams);

    var gameId = this.props.match.params.gameId;
    let customerId = this.context.customer.id;
    var docRef = db.collection("customers").doc(customerId)
      .collection('games').doc(gameId);

    docRef.update({
      updated: firebase.firestore.Timestamp.fromDate(new Date()),
      sortingEnabled: checked,
      teams: teams
    }).then(function () {
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }

  handleSaveEventClosestTo = (keypad, points) => {

    if (points === null) {
      keypad.points = '0';
    }
    else {
      keypad.points = points;
    }

    var teams = Object.assign([], this.state.teams);
    if (teams[0].players.length < 1) {
      return;
    }

    const eventIndex = findIndex(teams[0].players[0].points, function (o) { return o.eventId === keypad.eventId });
    const teamIndex = findIndex(teams, function (o) { return o.id === keypad.teamId });

    teams.forEach(team => {
      team.points[eventIndex].closestTo = parseFloat(keypad.points);

      team.players.forEach(player => {
        player.points[eventIndex].closestTo = parseFloat(keypad.points);
      });
    });

    if (teams[teamIndex].totalPoints !== null && teams[teamIndex].players.length > 0) {
      //Sätt placeringspoäng/poäng/placering på spelarna samt sortera spelarna efter placeringspoäng.
      teams[teamIndex].players = calculatePointsAndPlacements(teams[teamIndex].players, eventIndex, teams[teamIndex].players[0].points[eventIndex].calculationCategory, false, this.context.customer);

      //Sätter lagets totala antal poäng för grenen.
      var calculationCategory = teams[teamIndex].points[eventIndex].calculationCategory;
      const calculationFormat = teams[teamIndex].points[eventIndex].calculationFormat;
      teams[teamIndex].points[eventIndex].points = calculateEventTeamPoints(teams, teamIndex, eventIndex, calculationCategory, calculationFormat);
      //Sätt placeringspoäng/poäng/placering på lagen samt sortera lagen efter placeringspoäng.
      teams = calculatePointsAndPlacements(teams, eventIndex, calculationCategory, true, this.context.customer);
    }

    keypad.dialogStatus = false;

    this.setState({
      keypad: keypad
    });

    var gameId = this.props.match.params.gameId;
    let customerId = this.context.customer.id;
    var docRef = db.collection("customers").doc(customerId)
      .collection('games').doc(gameId);

    docRef.update({
      updated: firebase.firestore.Timestamp.fromDate(new Date()),
      teams: teams
    }).then(function () {
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }

  static contextType = AuthContext;
  render() {

    const { classes } = this.props;
    const { value, teams, individualPlayers, sortingEnabled } = this.state;

    if (teams !== null && value !== null) {

      return (
        <React.Fragment>
          <KeypadDialog
            keypad={this.state.keypad}
            savePoints={this.handleSavePoints}
            saveEventClosestTo={this.handleSaveEventClosestTo}
            dialogLabel="Lagets namn:"
            onClose={this.handleCloseKeypadDialog}
            keypadInEditMode={this.state.keypadInEditMode}
            setKeypadInEditToFalse={this.setKeypadInEditToFalse}
          />

          <PrintDialog
            open={this.state.printDialogIsOpen}
            toggleDialog={this.togglePrintDialog}
            confirm={this.handlePrint} />

          <div className={classes.root}>
            <AppBar position="fixed" style={{ backgroundColor: '#ff5960' }}>
              <Toolbar>
                <Hidden only="xs">
                  <img src={logo} alt="Logo" height="35" width="35" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(Routes.START)} />
                  <Typography className={classes.title} variant="h6" color="inherit" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(Routes.START)}>
                    Scoorly
                  </Typography>
                </Hidden>
                <Tabs value={value}
                  onChange={this.handleTabChange}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#FFF",
                    }
                  }}
                  scrollButtons="auto"
                  variant="scrollable"
                >

                  {teams.length > 1 && <Tab
                    classes={{ root: classes.tab, labelContainer: classes.labelContainer, label: classes.label }}
                    style={{ textTransform: 'none', color: '#FFF' }}
                    key={"teamsScore"}
                    value={"teamsScore"}
                    label={"Lag"}

                  />}

                  {teams.length > 1 && <Tab
                    classes={{ root: classes.tab, labelContainer: classes.labelContainer, label: classes.label }}
                    style={{ textTransform: 'none', color: '#FFF' }}
                    key={"individualScore"}
                    value={"individualScore"}
                    label={"Individuellt"}

                  />}

                  {this.state.tabs.map(tab => {
                    return (
                      <Tab classes={{ root: classes.tab, labelContainer: classes.labelContainer, label: classes.label }}
                        style={{ textTransform: 'none', color: '#FFF' }}
                        key={tab.id}
                        value={tab.id}
                        label={tab.name.toUpperCase()} />
                    )
                  })}

                </Tabs>

                <div className={classes.grow} />

                <label htmlFor="material-switch" style={{ marginRight: 5 }}>
                  <Typography style={{ display: 'inline-block', marginRight: 10, color: '#FFF', cursor: 'pointer' }}>Sortering</Typography>

                  <Switch
                    checked={sortingEnabled}
                    onChange={this.handleToggleSorting}
                    handleDiameter={12}
                    offColor="#fff"
                    onColor="#509CF5"
                    offHandleColor="#787878"
                    onHandleColor="#fff"
                    height={20}
                    width={37}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    className="react-switch"
                    id="material-switch"
                  />

                </label>


                <IconButton className={classes.button} aria-label="Home" onClick={() => this.props.history.push(Routes.START)}>
                  <FaHome style={{ fontSize: 20 }} />
                </IconButton>
                <IconButton className={classes.button} aria-label="Print" onClick={() => this.showPrintDialog()}>
                  <PrintIcon />
                </IconButton>
              </Toolbar>
            </AppBar>

            <div>&nbsp;</div>
            {(value === "teamsScore" && teams.length > 1 && teams[0].players.length > 0) &&
              <ScoreTableNoButtons
                items={teams}
                points={teams[0].players[0].points}
                infoText={<span>Alla poäng som visas i denna vy är<br />
                  lagens genomsnittliga poäng så att lag<br />
                  med olika antal spelare kan tävla mot varandra.</span>}
                onPrintRoute={false} />
            }

            {(value === "individualScore" && teams.length > 1 && teams[0].players.length > 0) &&
              /*   <ScoreTableNoButtons
                  items={individualPlayers}
                  points={individualPlayers[0].points}
                  infoText={''}
                  onPrintRoute={false} /> */
              <ScoreTable
                flashingPointId={this.state.flashingPointId}
                key={'individualPlayers'}
                team={{ players: individualPlayers }}
                keypad={this.state.keypad}
                openKeypadDialog={this.handleOpenKeypadDialog}
                gameId={this.props.match.params.gameId} />
            }

            {teams.map(team => {
              return (
                value === team.id &&
                <ScoreTable
                  flashingPointId={this.state.flashingPointId}
                  key={team.id}
                  team={team}
                  keypad={this.state.keypad}
                  openKeypadDialog={this.handleOpenKeypadDialog}
                  gameId={this.props.match.params.gameId} />
              )
            })}
          </div>
        </React.Fragment>
      )
    }
    return (<ProgressIndicator />)
  }
}

Play.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Play);
