import { orderBy, sumBy, maxBy, findIndex, filter } from 'lodash';
import * as constants from "../common/constants";
import { paddy } from "../common/helpers";

export function calculatePointsAndPlacements(itms, eventIndex, calculationCategory, isTeamCalculation, customer) {

  let sortedItms = null;

  if (calculationCategory === constants.calculationCategories.closest.value) {
    let closestTo = itms[0].points[eventIndex].closestTo;
    sortedItms = orderByClosest(itms, closestTo, eventIndex);

  } else if (calculationCategory === constants.calculationCategories.lowest.value) {
    //Sortera spelarna i laget baserat på hur många poäng de har för den grenen som finns på eventIndex. Lägsta poäng överst om kategorin är lowest
    sortedItms = orderBy(itms, function (o) { return o.points[eventIndex].points; }, 'asc');
  }
  else {
    //Sortera spelarna i laget baserat på hur många poäng de har för den grenen som finns på eventIndex. Högsta poäng överst om kategorin är highest
    sortedItms = orderBy(itms, function (o) { return o.points[eventIndex].points === null ? -1 : o.points[eventIndex].points; }, 'desc');
  }

  sortedItms = calculatePlacementPoints(sortedItms, eventIndex, calculationCategory, isTeamCalculation);

  //Räknar ut den totala placeringspoängen och totala poängen för varje spelare.
  //Bästa (lägsta) totala placeringspoängen leder/vinner.
  itms.forEach((itm, index) => {

    let totalPlacementpoints = sumBy(itms[index].points, function (o) { return o.placementpoints; });
    itms[index].totalPlacementpoints = totalPlacementpoints;

    //Här sätts teamets totala poäng.
    let totalPoints = sumBy(itms[index].points, function (o) { return o.points; });
    itms[index].totalPoints = totalPoints;

  });

  //Om det finns en skiljegren och poängen är högre i den grenen ska den spelaren med högst poäng
  //i skiljegrenen ha den bästa placeringen av dessa. Först sortering är dock alltid (totala placeringspoängen) totalPlacementpoints.
  const divIndex = findIndex(itms[0].points, function (o) { return o.isDividingEvent === true });
  if (divIndex > -1) {

    if (calculationCategory === constants.calculationCategories.closest.value) {

      itms = orderBy(itms, [
        function (o) { return o.totalPlacementpoints; },
        function (o) { return o.points[divIndex].diffToClosest === null ? -1 : o.points[divIndex].diffToClosest; }
      ], ["asc", "asc"]);

    } else if (calculationCategory === constants.calculationCategories.lowest.value) {
      itms = orderBy(itms, [
        function (o) { return o.totalPlacementpoints; },
        function (o) { return o.points[divIndex].points === null ? -1 : o.points[divIndex].points; }
      ], ["asc", "asc"]);
    }
    else {
      itms = orderBy(itms, [
        function (o) { return o.totalPlacementpoints; },
        function (o) { return o.points[divIndex].points === null ? -1 : o.points[divIndex].points; }
      ], ["asc", "desc"]);

    }
  } else {
    //Sortera spelarna efter placeringspoäng (totalPlacementpoints) lägsta högst upp = leder
    itms = orderBy(itms, function (o) { return o.totalPlacementpoints });
  }

  //Om kunden valt att använda beräkningstypen totalPoints då 
  //skippar vi allt vad planeringspoäng är och sorterar på högsta totalpoängen.
  if(typeof customer !== "undefined" && typeof customer.calculationType !== "undefined" && customer.calculationType === constants.calculationTypes.totalPoints.value) {
    itms = orderBy(itms, function (o) { return o.totalPoints }, "desc");
  }

  //Räkna ut placeringen för respektive spelare.
  itms.forEach((itm, index) => {

    //Första spelaren i iterationen har alltid placering 1
    if (index === 0) {
      itm.placement = 1;
      return;
    }

    //Utgår ifrån att spelaren får en placering högre än föregående i iterationen.
    itm.placement = itms[index - 1].placement + 1;

    if(typeof customer !== "undefined" && typeof customer.calculationType !== "undefined" && customer.calculationType === constants.calculationTypes.totalPoints.value) {
      if (itm.totalPoints === itms[index - 1].totalPoints) {
      //Utgår ifrån att dom då får samma placering.
      itm.placement = itms[index - 1].placement;
         //Om det finns en skiljegren.
         if (divIndex > -1) {
          if (compareByCalculationCategory(itm.points[divIndex]["points"], itms[index - 1].points[divIndex]["points"], calculationCategory)) {
            itm.placement = itms[index - 1].placement + 1;
          }
        }
      }
    } else {
    //Om totala placeringspoängen är lika som föregående spelare i iterationen.
    if (itm.totalPlacementpoints === itms[index - 1].totalPlacementpoints) {

      //Utgår ifrån att dom då får samma placering.
      itm.placement = itms[index - 1].placement;

      //Egenskapens som ska beräknas på. Beroende på vilken calculationCategory som gäller.
      var compareProp = 'points';
      if (calculationCategory === constants.calculationCategories.closest.value) {
        compareProp = 'diffToClosest'
      }

      //Om det finns en skiljegren.
      if (divIndex > -1) {
        if (compareByCalculationCategory(itm.points[divIndex][compareProp], itms[index - 1].points[divIndex][compareProp], calculationCategory)) {
          itm.placement = itms[index - 1].placement + 1;
        }
      }
    }
  }
  });

  return itms;
}

function compareByCalculationCategory(num1, num2, calculationCategory) {
  if (calculationCategory === constants.calculationCategories.closest.value) {
    if (num1 > num2)
      return true;
  } else {
    if (num1 < num2)
      return true;
  }

  return false;
}

export function orderByClosest(list, num, eventIndex) {
  // temporary array holds objects with position and sort-value
  var mapped = list.map(function (el, i) {
    return { index: i, value: Math.abs(el.points[eventIndex].points - num) };
  });

  // sorting the mapped array containing the reduced values
  mapped.sort(function (a, b) {
    return a.value - b.value;
  });

  // return the resulting order
  return mapped.map(function (el) {
    return list[el.index];
  });
}

function calculatePlacementPoints(itms, eventIndex, calculationCategory, isTeamCalculation) {

  if (calculationCategory === constants.calculationCategories.closest.value) {
    //Räknar ut placeringspoäng för grenen för respektive spelare.
    itms.forEach((itm, index) => {

      let diffToClosest = Math.abs(itms[index].points[eventIndex].points - itms[index].points[eventIndex].closestTo);
      //Rounding with max 8 decimals.
      diffToClosest = parseFloat(diffToClosest.toFixed(8));
      itms[index].points[eventIndex].diffToClosest = diffToClosest;

      //Om index är 0 (först bland de sorterade spelarna) 
      //denna spelare ligger bäst i denna gren
      //och ska alltid få bästa placeringspoäng = 1
      if (index === 0) {
        itms[index].points[eventIndex].placementpoints = 1;
        return;
      }

      //Om spelarens differens mot närmast poäng i grenen är lika stor som föregående spelares poäng i loopen
      //då ska denna spelares placeringspoäng sättas till samma som föregåendes spelares placeringspoäng.
      let preDiffToClosest = itms[index - 1].points[eventIndex].diffToClosest;
      //Rounding with max 8 decimals.
      preDiffToClosest = parseFloat(preDiffToClosest.toFixed(8));
      if (diffToClosest === preDiffToClosest) {
        itms[index].points[eventIndex].placementpoints = itms[index - 1].points[eventIndex].placementpoints;
      }
      else {
        //Om placementCalculation är following samt ej lagberäkning (isTeamCalculation) sätt placeringspoängen till 1 poäng mer än föregående spelares placeringspoäng i grenen.
        if(itms[index].points[eventIndex].placementCalculation === constants.placementCalculation.following.value && isTeamCalculation === false) {
          itms[index].points[eventIndex].placementpoints = itms[index - 1].points[eventIndex].placementpoints + 1;
        } else {
          //I vanliga fall (default) sätt placeringspoängen till index + 1 i grenen.
          itms[index].points[eventIndex].placementpoints = index + 1;
        }
      }
    });
  } else {
    //Räknar ut placeringspoäng för grenen för respektive spelare.
    itms.forEach((itm, index) => {
      //Om index är 0 (först bland de sorterade spelarna) 
      //denna spelare ligger bäst i denna gren
      //och ska alltid få bästa placeringspoäng = 1
      if (index === 0) {
        itms[index].points[eventIndex].placementpoints = 1;
      }
      //Om spelarens poäng i grenen är samma poäng som föregående spelares poäng i loopen
      //då ska denna spelares placeringspoäng sättas till samma som föregåendes spelares placeringspoäng.
      else if (itms[index].points[eventIndex].points === itms[index - 1].points[eventIndex].points) {
        itms[index].points[eventIndex].placementpoints = itms[index - 1].points[eventIndex].placementpoints;
      }
      else {
        //I övriga fall om spelarens poäng är mindre än föregående spelares poäng i loopen.
        //Om placementCalculation är following samt ej lagberäkning (isTeamCalculation) sätt placeringspoängen till 1 poäng mer än föregående spelares placeringspoäng i grenen.
        if(itms[index].points[eventIndex].placementCalculation === constants.placementCalculation.following.value && isTeamCalculation === false) {
          itms[index].points[eventIndex].placementpoints = itms[index - 1].points[eventIndex].placementpoints + 1;
        } else {
          //I vanliga fall (default) sätt placeringspoängen till index + 1 i grenen.
          itms[index].points[eventIndex].placementpoints = index + 1;
        }
      }
    });
  }
  return itms;
}

export function calculateEventTeamPoints(teams, teamIndex, eventIndex, calculationCategory, calculationFormat) {

  //Räkna ut lagets genomsnittliga poäng. Det blir rättvist då det ibland är olika antal spelare med i olika lag.
  //Lagen kan då tävla emot varandra oavsett antal spelare i respektive lag.
  let totPoints = sumBy(teams[teamIndex].players, function (o) { return o.points[eventIndex].points });
  let averagePoints = 0;

  if (calculationCategory === constants.calculationCategories.closest.value) {
    let playersWithPoints = filter(teams[teamIndex].players, function (o) { return o.points[eventIndex].points !== null; });

    averagePoints = (totPoints / playersWithPoints.length);

    averagePoints = Math.round(averagePoints * 10) / 10;
    return averagePoints;
  } else {

    var teamWithMostPlayers = maxBy(teams, function (t) { return t.players.length });
    averagePoints = (totPoints / teams[teamIndex].players.length) * teamWithMostPlayers.players.length;
    //Slår ut lagets totala snitt på antalet spelare i det lag som har flest spelare.
    averagePoints = averagePoints / teamWithMostPlayers.players.length;
    averagePoints = Math.round(averagePoints * 10) / 10;

    //Om det är en gren med tidsformat räknar vi enligt följande:
    //Gör om poängen i millisekunder till minuter genom poäng/60000 och sedan summera alla minutrarna.
    //Konvertera sedan tillbaka summan av minutrarna till millisekunder genom minutrarna * 60000
    if (calculationFormat === constants.calculationFormats.time.value) {

      let pointsArr = teams[teamIndex].players.map((player) => {
        
        let x = player.points[eventIndex].points;
        
        if (x != null) {
          //Tillverkar nollor framför så vi alltid har ett 6-siffrigt objekt att jobba med.
          x = paddy(x, 6);
          let xStr = x.toString();

          //Konverterar minutrarna till millisekunder.
          let minutesStr = xStr.slice(0, 1);
          let minutes = parseInt(minutesStr);
          let minutesInMillis = minutes * 60000;

          //Konverterar sekundrarna till millisekunder.
          let secondsStr = xStr.slice(1, 3);
          let seconds = parseInt(secondsStr);
          let secondsInMillis = seconds * 1000;

          //Plockar ut millisekunder.
          let millisStr = xStr.slice(3, 6);
          let millisInMillis = parseInt(millisStr);
          return minutesInMillis + secondsInMillis + millisInMillis;
        } else {
          return 0;
        }
      });

      totPoints = pointsArr.reduce((a, b) => a + b, 0);
      averagePoints = (totPoints / teams[teamIndex].players.length) * teamWithMostPlayers.players.length;
      averagePoints = averagePoints / teamWithMostPlayers.players.length;
      averagePoints = msToTime(averagePoints);
    
    }

    return averagePoints;
  }

}

function msToTime(s) {
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;
  
  secs = secs < 10 ? '0' + secs : '' + secs;

  if(ms < 10) {
    ms = "00" + ms
  } else if(ms < 100) {
    ms = "0" + ms
  }

  return parseInt(mins+secs+ms);
}

function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}